import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({ data }) => (
  <Layout location="about">
    <SEO title="About" />
    <div className="mx-auto prose lg:prose-lg divide-y divide-gray-200">
      <article>
        <h1 className="nomargin">Dr Beverley Clack</h1>
        <h6>BSc Hons MSc PhD DClinPsychol</h6>
        <Img
          className="mx-auto"
          style={{ maxWidth: "402px", maxHeight: "604px" }}
          fluid={data.file.childImageSharp.fluid}
          alt="Photo of Beverley Clack"
        />
        <p>
          I am a registered Clinical Psychologist (
          <a
            href="https://www.hcpc-uk.org/check-the-register/professional-registration-detail/?query=PYL29265&profession=PYL"
            target="_blank"
            rel="noopener noreferrer"
          >
            PYL29265
          </a>
          ). I qualified in 2012 and I have worked as a Senior Clinical
          Psychologist within the NHS since 2015. I have worked across both
          inpatient (acute mental health problems as well as rehabilitation) and
          community settings (including the crisis resolution and home treatment
          team) and have worked with adults of all ages as well as with a range
          of difficulties, including complex difficulties. I am passionate about
          working with you to gain an understanding of what feels difficult or
          stuck (and why) and working with you to build the life you would like.
        </p>
        <p>
          I have a long-standing interest in mindfulness and my doctoral thesis
          focused on how mindfulness reduces distress and increases wellbeing. I
          have a personal practice in mindfulness that began in 2007 and this
          forms the foundation for all third wave CBT therapies that influence
          the therapeutic work I offer. Building on this, since qualifying, I
          have completed training in Teaching Mindfulness, with additional
          training on Mindful Movement (2014). I have also completed training in
          Eye Movement Desensitisation and Reprocessing (EMDR), Compassion
          Focussed Therapy (CFT) and Dialectical Behaviour Skills (DBT Skills).
        </p>
        <p>
          I have worked solely in independent practice since September 2020.
        </p>
        <h2 id="qualifications">Qualifications</h2>
        <p>
          I completed a Doctorate in Clinical Psychology at the University of
          Liverpool in 2012.
        </p>
        <p>
          I also hold a PhD in Social Psychology of Prejudice/Segregation
          (Lancaster University, 2008), an MSc in Psychological Research Methods
          (Lancaster University, 2002 - Distinction) and a BSc (Hons) in
          Psychology (First Class).
        </p>
      </article>
      <div className="pt-6 pb-16">
        <p>
          If you want to talk to me in more detail about the services I offer
          then please <Link to="/contact/">contact me</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "BeverleyClack.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 402, maxHeight: 604) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage
